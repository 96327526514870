import axios from "axios";
import { useCallback, useState } from "react";
import { LendHistoryInfo } from "../types/api/LendHistoryInfo";
import { BookControlForm } from "../types/form/BookControlForm";

export const useFetchLendHistory = () => {
  const BASE_API_URL: string | undefined = process.env.REACT_APP_API_BASE_URL;
  const FETCH_LENDHISTORY_API_URL: string = "/api/bookLendReturnHistory";
  const [lendHistoryList, setLendHistoryList] = useState<
    Array<LendHistoryInfo>
  >([]);

  const fetchLendHistoryList = useCallback(
    (data: BookControlForm, token: string) => {
      axios
        .get(BASE_API_URL + FETCH_LENDHISTORY_API_URL, {
          headers: {
            Authorization: token
          },
          params: data,
          data: {},
        })
        .then((response) => {
          let fetchLendHistoryList: Array<LendHistoryInfo> = [];
          response.data.map((data: LendHistoryInfo) => {
            fetchLendHistoryList.push({
              employeeFullName: data.employeeFullName,
              lendDate: data.lendDate,
              returnDate: data.returnDate,
              remarks: data.remarks,
            });
            return true;
          });
          setLendHistoryList(fetchLendHistoryList);
        })
        .catch((err) => {
          return;
        });
    },
    []
  );

  return { fetchLendHistoryList, lendHistoryList };
};
